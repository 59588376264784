import React, { useEffect } from 'react'
import { useRouteMatch, NavLink } from 'react-router-dom'
import { Components } from '../data/data.js'
import { isMobile } from "react-device-detect";


export default function Navigation({ setCurrentPlanet }) {
  let match = useRouteMatch("/:slug");
  const findMatch = match ? Components.findIndex(k => k.slug === match.params.slug) : 0;

  useEffect(() => {
    setCurrentPlanet(findMatch === -1 ? 0 : findMatch)
  }, [match, findMatch, setCurrentPlanet])

  return (
    <nav>
      <ul>
        {Components.map(item => (
          <li key={item.id} className={"li-" + item.slug} style={{ left: (item.coordinate.x / 250) + 1 + "em", top: (item.coordinate.y / 250) + 1 + "em" }}>
          <NavLink to={"/" + item.slug}>
            <div className={"nav-dot"} />
            {!isMobile
            ? <span className={"nav-title animated-text-shadow"}>{item.title}</span>
            : null}
          </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
