import React from 'react'

const RenderDate = ({ startTime, endTime }) => {
  const dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

  return (
    <p className="ticket-date">
        {startTime ? new Date(startTime).toLocaleDateString('en-GB', dateFormat) : "TBA"}<br />
        {startTime
          ? new Date(startTime).toLocaleTimeString('en-GB', { timeStyle: 'short' })
            + (endTime ? "–" + new Date(endTime).toLocaleTimeString('en-GB', { timeStyle: 'short' }) : " until late")
          : null}
    </p>
  )
}

export default RenderDate
