import React from 'react'
import DottedLogotype from '../assets/dotted-s-logo.svg'
import Corner from '../assets/icons/corner.svg'
import Logo from '../assets/silverring-logo7.svg'
import LogoS from '../assets/silverring-small-logotype.svg'
import TransmissionIcon from '../assets/icons/star.svg'
import GlobeIcon from '../assets/icons/globe.svg'
import ReleaseIcon from '../assets/icons/space-eye.svg'
import SpaceFlowerIcon from '../assets/icons/space-flower.svg'
import { Link } from 'react-router-dom'
import { isBrowser } from "react-device-detect";

const Crew = ({ loadingData, data }) => {
  if (loadingData) {return <p>Loading</p>}
  if (!data.total) {return <div>No crew members found atm</div>}


  const members = data.items[0].fields.crew.map(item => (
      {
        id: item.sys.id,
        name: item.fields.name,
        video: item.fields.video,
        sssId: item.fields.id ? item.fields.id : "Unknown",
        role: item.fields.role ? item.fields.role : "Unknown",
        element: item.fields.element ? item.fields.element : "Unknown",
        origin: item.fields.origin ? item.fields.origin : "Unknown",
        quote: item.fields.quote,
        redirect: getLink(item.sys.id, item.fields.slug)
      }
    ))

  return (
    <>
      <img src={DottedLogotype} className="logotype" alt="Silverring Stellar Sound" style={{ width: "20em", marginLeft: "0"}} />
      <h3 className="animated-text-shadow">Crew Cards</h3>
      <div className="crew-list">
      {members.map((member, i) =>
        <MemberLink key={i} redirect={member.redirect}>
        <div className="crew-card shadow float-animation">

          <div className="crew-card-title">
            {member.name}
          </div>
          <div className="crew-card-avatar">
            <div className="crew-card-avatar-video">
              {member.video
              ?  <video playsInline muted autoPlay loop>
                  <source src={member.video.fields.file.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              : <div>ERROR<br />NO REPRESENTATION FOUND</div>}
            </div>
            <Aim />
            <div className="crew-card-logotype-s">
              <img src={LogoS} alt="Silverring Stellar Sound" />
            </div>
          </div>

          <div className="crew-card-information">
            <div className="crew-card-information-l2">
              <div className="crew-card-information-l3">
                <img src={ReleaseIcon} alt="ID" />
              </div>
              <div className="crew-card-information-l4">
                {member.sssId}
              </div>
            </div>
            <div className="crew-card-information-l2">
              <div className="crew-card-information-l3">
                <img src={TransmissionIcon} alt="Role" />
              </div>
              <div className="crew-card-information-l4">
                {member.role}
              </div>
            </div>
            <div className="crew-card-information-l2">
              <div className="crew-card-information-l3">
                <img src={SpaceFlowerIcon} alt="Element" />
              </div>
              <div className="crew-card-information-l4">
                {member.element}
              </div>
            </div>
            <div className="crew-card-information-l2">
              <div className="crew-card-information-l3">
                <img src={GlobeIcon} alt="Origin" />
              </div>
              <div className="crew-card-information-l4">
                {member.origin}
              </div>
            </div>
          </div>

          <div className="crew-card-logotype">
            <img src={Logo} alt="Silverring Stellar Sound" />
          </div>

        </div>
        {member.quote && isBrowser
          ? <div className="crew-card-quote">
              {member.quote}
            </div>
        : null}
        </MemberLink>
      )}
      </div>
      <p>For bookings and any other inquiries: <a href="mailto:communicator@silverringstellarsound.com">communicator@silverringstellarsound.com</a></p>
    </>
  )
}

export default Crew

const MemberLink = ({ redirect, children }) => {
  if (redirect.external) {
    return (
      <a className="crew-card-link" target="_blank" rel="noopener noreferrer" href={redirect.link}>
      {children}
      </a>
    )
  } else {
    return (
      <Link className="crew-card-link" to={redirect.link}>
      {children}
      </Link>
    )
  }
}

const Aim = () => {
  const corners = []
  for(let i = 0; i < 4; i++) {
    corners.push(
      <div key={i} className={"absolute aim-corner"}>
        <img src={Corner} alt="" style={{ transform: "rotateZ(" + i * 90 + "deg)"}} />
      </div>
    )
  }

  return (<div className="aim">{corners}</div>)
}

function getLink(id, slug) {
  if (id === "7dW6GUnSO3QB9eLcDfbFuM") {
    return ({
      link: "https://www.instagram.com/pbeatgirl/",
      external: true
    })
  } else if (id === "7sn6LHN0buJLC2gAKazxJN") {
    return ({
      link: "https://www.instagram.com/hektuhr/",
      external: true
    })
  } else {
    return ({
      link: slug,
      external: false
    })
  }
}
