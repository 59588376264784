import React, { useState } from 'react'
import { useHotkeys } from "react-hotkeys-hook";
import KrypinRenderer from './KrypinRenderer'
import Record from './Record'
import Ticket from './Ticket'

const Profile = ({ user, loadingData, data }) => {
  if (loadingData === true) {return <p>Loading</p>}

  if (user.includes("Papa Musiq")) {
    return <PapaMusiq data={data.items[0].fields.crew.find(k => k.sys.id === "3I6EbSPYNx2LPXbWC1Bnl2")} />
  }

  if (user.includes("Isidor")) {
    return <Isidor data={data} />
  }

  return (
    <div>
      No user found
    </div>
  )
}

export default Profile


const PapaMusiq = ({ data }) => {

  const keys = [
    {
      hidden: true,
    },
    {
      title: null,
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: "Audio",
      assignedKey: "a",
      link: "https://open.spotify.com/artist/0ApJF08Dso17M7DSS82JYd?si=Xs1nBlKISoeqtLMOMLHubg"
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
    },
    {
      hidden: true,
    },

    {
      title: null,
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: "Video",
      assignedKey: "v",
      link: "https://www.youtube.com/watch?v=N_GC4gtW4OA"
    },
    {
      title: null,
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
    },

    {
      title: null,
      allDevices: false
    },
    {
      title: null,
    },
    {
      title: null,
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
    },
    {
      title: "Cargo",
      assignedKey: "c",
      link: "https://papamusiq.bandcamp.com/merch"
    },

    {
      title: null,
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
    },
    {
      title: "Radio Papa",
      assignedKey: "r",
      link: "https://open.spotify.com/playlist/2dD7LtZaaAvu5Otv85SRu2?si=MdBbgY8WSHGcTAE05S6g1A"
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
    },

    {
      hidden: true,
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: "Social",
      assignedKey: "s",
      link: "https://www.instagram.com/papamusiq/"
    },
    {
      title: null,
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
      allDevices: false
    },
    {
      title: null,
    },
    {
      hidden: true,
    },
  ]

  useHotkeys('a', () => window.open(keys[keys.findIndex(key => key.assignedKey === "a")].link, "_blank"))
  useHotkeys('v', () => window.open(keys[keys.findIndex(key => key.assignedKey === "v")].link, "_blank"))
  useHotkeys('p', () => window.open(keys[keys.findIndex(key => key.assignedKey === "p")].link, "_blank"))
  useHotkeys('c', () => window.open(keys[keys.findIndex(key => key.assignedKey === "c")].link, "_blank"))
  useHotkeys('r', () => window.open(keys[keys.findIndex(key => key.assignedKey === "r")].link, "_blank"))
  useHotkeys('s', () => window.open(keys[keys.findIndex(key => key.assignedKey === "s")].link, "_blank"))

  return (
    <div style={{ textAlign: "center" }}>
    <h3 className="animated-text-shadow">Papa Musiq’s Control Panel</h3>
    <div className="control-panel-release">
      <div className="float-animation">
        {data.fields.featuredRelease.sys.contentType.sys.id === "transmission" ? <Ticket data={data.fields.featuredRelease} shadow="yellow" expanded={true} /> : null}
        {data.fields.featuredRelease.sys.contentType.sys.id === "record" ? <Record data={data.fields.featuredRelease} shadow="yellow" /> : null}
      </div>
      <img className="image-neue" src="https://web.archive.org/web/20121111060606/http://www.geocities.com/binaryan/new.gif" alt="NEW" />
    </div>
    <div className="keyboard">
      {keys.map((key, i) =>
        <div key={i} className={"yellow-shadow key" + (key.hidden ? " visibility-hidden" : " visible") + (key.allDevices === false ? " key-dynamic" : " key-static") + (key.title && " cursor-pointer")} onClick={() => key.link ? window.open(key.link, "_blank") : null}>
          {key.assignedKey ? <div className="key-assigned">{key.assignedKey}</div> : null }
          {key.title}
        </div>
      )}
    </div>
    </div>
  )
}


const Isidor = ({ data }) => {
  const [filter, setFilter] = useState(null)

  const entries = data.items[0].fields.isidorRef.map(ref => ({
    title: ref.fields.title,
    associates: produceMoreData(ref.fields),
    year: ref.fields.released ? ref.fields.released : ref.sys.createdAt,
    link: ref.fields.externalLink
}))

  const categories = entries.map((entry, i) => (entries[i].associates ? findCategory(entries[i]) : false))

  return (
    <>
      <div className="dialogue-box shadow">

        <div className="dialogue-box-about">
          <KrypinRenderer data={data.items[0].fields.isidor} />
        </div>
        <div className="dialogue-box-main">
          <div className="dialogue-box-navigation">
            <ul>
              <li className={"pink-shadow " + (filter === null ? "active" : "not-active")} onClick={() => setFilter(null)}>All work</li>
              <li className={"pink-shadow " + (filter === "produced" ? "active" : "not-active")} onClick={() => setFilter(filter === "produced" ? null : "produced")}>Produced</li>
              <li className={"pink-shadow " + (filter === "mixed" ? "active" : "not-active")} onClick={() => setFilter(filter === "mixed" ? null : "mixed")}>Mixed</li>
              <li className={"pink-shadow " + (filter === "mastered" ? "active" : "not-active")} onClick={() => setFilter(filter === "mastered" ? null : "mastered")}>Mastered</li>
            </ul>
          </div>
          <div className="dialogue-box-content">
            {entries.map((entry, i) =>
              <div key={i} className={"dialogue-box-post " + (filter === "produced" && categories[i].produced || filter === "mixed" && categories[i].mixed || filter === "mastered" && categories[i].mastered || filter === null ? "opacity-1" : "opacity-quarter")}>
                {entry.link
                    ? <>
                        <h5>
                          <a href={entry.link} target="_blank" rel="noopener noreferrer">
                            {entry.title} ({new Date(entry.year).getFullYear()})
                          </a>
                        </h5>
                        &emsp;
                        <span className="no-break">
                          [
                            <a href={entry.link} target="_blank" rel="noopener noreferrer">
                            EXTERNAL WWW: {extractDomain(entry.link)}]
                            </a>
                        </span>
                      </>
                    : <h5>{entry.title} ({new Date(entry.year).getFullYear()})</h5>
                }
                <br />
                {entry.associates.map((asso, i) =>
                  entry.associates.length === (i + 1)
                  ? <span key={i}><span>{asso.role.replace(" ", "_")}:&nbsp;</span><span className="uppercase">{asso.name.replace(" ", "\u00A0")}</span></span>
                  : <span key={i}><span>{asso.role.replace(" ", "_")}:&nbsp;</span><span className="uppercase">{asso.name.replace(" ", "\u00A0")}, </span></span>
                )}
                <br />

              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

function produceMoreData(x) {
  const y = []
  if (x.artists) {x.artists.map(z => y.push({name: z.fields.name, role: "Artist"}))}
  if (x.producers) {x.producers.map(z => y.push({name: z.fields.name, role: "Producer"}))}
  if (x.lyricists) {x.lyricists.map(z => y.push({name: z.fields.name, role: "Lyricist"}))}
  if (x.masteringEngineers) {x.masteringEngineers.map(z => y.push({name: z.fields.name, role: "Mastering Engineer"}))}
  if (x.mixingEngineers) {x.mixingEngineers.map(z => y.push({name: z.fields.name, role: "Mixing Engineer"}))}
  if (x.recordingEngineers) {x.recordingEngineers.map(z => y.push({name: z.fields.name, role: "Recording Engineer"}))}
  if (x.additionalProducers) {x.additionalProducers.map(z => y.push({name: z.fields.name, role: "Additional Producer"}))}
  if (x.coProducers) {x.coProducers.map(z => y.push({name: z.fields.name, role: "Co-Producer"}))}
  if (x.composers) {x.composers.map(z => y.push({name: z.fields.name, role: "Composer"}))}
  if (x.arrangers) {x.arrangers.map(z => y.push({name: z.fields.name, role: "Arranger"}))}
  if (x.vocalists) {x.vocalists.map(z => y.push({name: z.fields.name, role: "Vocalist"}))}
  if (x.assistantMixingEngineers) {x.assistantMixingEngineers.map(z => y.push({name: z.fields.name, role: "Assistant Mixing Engineer"}))}
  if (x.assistantMasteringEngineers) {x.additionalProducers.map(z => y.push({name: z.fields.name, role: "Assistant Mastering Engineer"}))}
  if (x.artDirectors) {x.artDirectors.map(z => y.push({name: z.fields.name, role: "Art Director"}))}
  if (x.albumArt) {x.albumArt.map(z => y.push({name: z.fields.name, role: "Album Art"}))}

  return y
}

function findCategory(x) {
  return {
    produced: x.associates.findIndex(y =>
      y.name.includes("Isidor") && (y.role === "Producer" || y.role === "Additional Producer" || y.role === "Co-Producer")) !== -1
      ? true : false,
    mixed: x.associates.findIndex(y => y.name.includes("Isidor") && (y.role === "Mixing Engineer" || y.role === "Assistant Mixing Engineer")) !== -1 ? true : false,
    mastered: x.associates.findIndex(y => y.name.includes("Isidor") && (y.role === "Mastering Engineer" || y.role === "Assistant Mastering Engineer")) !== -1 ? true : false,
  }
}

function extractDomain(url) {
  var domain;
  if (url.indexOf("://") > -1) {
    domain = url.split('/')[2];
  }
  else {
    domain = url.split('/')[0];
  }
  if (domain.indexOf("www.") > -1) {
    domain = domain.split('www.')[1];
  }
  domain = domain.split(':')[0];
  domain = domain.split('?')[0];

  return domain;
}
