import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Ticket from './Ticket'
import Record from './Record'

const TextRenderer = ({ data }) => {

  if (!data) {return null}

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.sys.contentType.sys.id === "transmission") {
          return <Ticket data={node.data.target} />
        } else if (node.data.target.sys.contentType.sys.id === "record") {
          return <Record data={node.data.target} />
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields
        return <img src={file.url} alt={title ? title : file.url} className="embedded-image" />
      },
      [BLOCKS.HEADING_3]: (node) => {
        const hrs = []
        for(let i = 0; i < 1; i++) {
          hrs.push(
            <hr key={i} style={{ top: (100 / (4 - 2)) + ((100 / (4 - 2)) * i) + "%" }} />
          )
        }
        return (
          <div className="dialogue-box-title">
            {hrs}
            <span>{node.content[0].value}</span>
          </div>
        )
      },
    },

    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <>
      {documentToReactComponents(data, options)}
    </>
  )
}

export default TextRenderer
