import React, { useState } from "react"
import useMusicPlayer from "./useMusicPlayer";

const Controls = () => {
  const { trackPlayer, isPlayingTracks, currentTrackName, togglePlay } = useMusicPlayer();
  const [timeLeftState, setTimeLeftState] = useState("-0:00")

  function getTime() {
    if (isNaN(trackPlayer.duration)){
      setTimeLeftState("-0:00")
    } else {
      setTimeLeftState(formatTime(trackPlayer.duration - trackPlayer.currentTime))
    }
  }

  trackPlayer.ontimeupdate = () => getTime();

  return (
    <>
    <div className={"now-playing"} onClick={togglePlay}>
      {isPlayingTracks
      ? <>
          Now playing: {currentTrackName}
        </>
      : "Nothing playing atm"}
    </div>

      <section className="decks">
        <div className={"record-outer-circle shadow " + (isPlayingTracks && currentTrackName ? "record-playing " : "record-paused ")} onClick={togglePlay}>
          <div className="record-label-circle shadow" />
          <div className="record-inner-circle shadow" />
          <div className="record-tag">
            {timeLeftState}
          </div>
        </div>
      </section>

    </>
  )
}

export default Controls

function formatTime(secs, format) {
  var hr  = Math.floor(secs / 3600);
  var min = Math.floor((secs - (hr * 3600))/60);
  var sec = Math.floor(secs - (hr * 3600) -  (min * 60));

  if (min < 10){
    min = "0" + min;
  }
  if (sec < 10){
    sec  = "0" + sec;
  }

  return min + ':' + sec;
}
