import React from 'react'
import Logotype from '../assets/silverring-full-logo.svg'

const Intro = ({ visible }) => {
  return (
    <section className={"intro" + (visible ? " opacity-1" : " opacity-0")}>
      <div>
        <img src={Logotype} alt="Silverring Stellar Sound" />
        <p>
          Silverring Stellar Sound is a collaborative spaceship and record label with a mission to create and curate music and human expression in all forms.<br /><br />
          Loading...
        </p>
      </div>
    </section>
  )
}

export default Intro
