import React from 'react'
import Featured from '../components/Featured'
import Profile from '../components/Profile'
import Crew from '../components/Crew'
import Timeline from "../components/Timeline";

export const Components = [
{
  id: 0,
  slug: "transmissions",
  title: 'Transmissions',
  component: <Featured />,
  coordinate: {x: 50, y: 50},
  scrollIntoView: {block: "start", inline: "center"},
},
{
  id: 1,
  slug: "crew",
  title: 'Crew Cards',
  component: <Crew />,
  coordinate: {x: 400, y: 1200},
  scrollIntoView: {block: "start", inline: "center"},
},
{
  id: 2,
  title: 'Silverring System Storage',
  slug: "storage",
  component: <Timeline />,
  coordinate: {x: 50, y: 600},
  scrollIntoView: {block: "start", inline: "center"},
},
{
  id: 3,
  title: 'Papa Musiq’s Control Panel',
  slug: "papa-musiq",
  component: <Profile user={"Papa Musiq"} />,
  coordinate: {x: 800, y: 200},
  scrollIntoView: {block: "start", inline: "center"},
},
{
  id: 4,
  title: 'Isidor Technician',
  slug: "isidor-technician",
  component: <Profile user={"Isidor Estrada"} />,
  coordinate: {x: 1100, y: 1100},
  scrollIntoView: {block: "start", inline: "center"},
},
]
