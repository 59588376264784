import React, { useState, useEffect, memo } from 'react'
import Corner from '../assets/corner.svg'
import Transmitter from '../components/Transmitter'
import { MusicPlayerProvider } from "../components/MusicPlayerContext";
import PlayerControls from "../components/PlayerControls";
import Navigation from './Navigation'
import Intro from './Intro'
import { BrowserRouter as Router } from 'react-router-dom'
import Sky from './Sky'

export default function Layout({ children, setCurrentPlanet, planetData }) {
  const [data, loadingData] = planetData
  const [intro, setIntro] = useState(true)
  useEffect(() => {
    let interval = setInterval(() => setIntro(false), 5000)
    return () => clearInterval(interval)
  }, [])

  if (loadingData) {return <Intro />}

  return (
    <Router>
      <div className="bottom-gradient" />
      <div className="map shadow" />
      <div className="map-silverring shadow" />
      <Aim />
      <Sky />
      <main>
        {children}
      </main>
      <Navigation setCurrentPlanet={setCurrentPlanet} />
      <Transmitter data={data} />
      <MusicPlayer loadingData={loadingData} data={data} />
      <Intro visible={intro} />
    </Router>
  )
}

const Aim = () => {
  const corners = []
  for(let i = 0; i < 4; i++) {
    corners.push(
      <div key={i} className={"fixed corner"} style={{ transform: "rotateZ(" + i * 90 + "deg)"}}>
        <img src={Corner} alt="" />
      </div>
    )
  }
  return (<div>{corners}</div>)
}

const MusicPlayer = ({ loadingData, data }) => {

  return (
    <MusicPlayerProvider loadingData={loadingData} data={data}>
      <section className="music-player">
        <PlayerControls />
      </section>
    </MusicPlayerProvider>
  )
}
