import React, { useState } from 'react'
import Logo from '../assets/silverring-logo-2.svg'
import TransmissionIcon from '../assets/icons/gathering.svg'
import UnknownIcon from '../assets/icons/alien.svg'
import ReleaseIcon from '../assets/icons/sound-2.svg'
import Ticket from './Ticket'
import Record from './Record'

const dateFormat = {weekday: 'short', year: 'numeric', month: 'short', day: '2-digit' }

const Timeline = ({ loadingData, data }) => {
  const [view, setView] = useState("all")
  if (loadingData) {return <p>Loading</p>}

  const allEntries = data.items[0].fields.storage
  const sortedEntries = allEntries.sort((a, b) => new Date(b.fields.startTime ? b.fields.startTime : b.fields.date) - new Date(a.fields.startTime ? a.fields.startTime : a.fields.date))

  for(let i = sortedEntries.length; i < (Math.ceil(sortedEntries.length / 10) * 10); i++) {
    sortedEntries.unshift(
      {
        fields: {
          title: "Silverring Stellar Sound",
        },
        sys: {
          contentType: {
            sys: {
              id: "unknown"
            }
          }
        }
      }
    )
  }

  const button = [
    {
      transmission: (
        <button type="button" style={{ opacity: view !== "all" && view !== "transmission" ? .25 : 1 }} onClick={() => setView(view === "transmission" ? "all" : "transmission")}>
          <img src={TransmissionIcon} alt="Transmissions" />
        </button>
    )
    },
    {
      release: (
      <button type="button" style={{ opacity: view !== "all" && view !== "record" ? .25 : 1 }} onClick={() => setView(view === "record" ? "all" : "record")}>
        <img src={ReleaseIcon} alt="Releases" />
      </button>
    )
    }
  ]

  return (
    <>
      <img src={Logo} className="logo shadow-filter" alt="" />
      <h3 className="animated-text-shadow">Future and Past</h3>
      <p className="timeline-filter">
        Filter by:&ensp;{button[0].transmission} {button[1].release}
      </p>
      <ul className="timeline-list">
        {sortedEntries.map((entry, i) =>
          entry.sys.contentType.sys.id === "transmission" ? <RenderTransmission key={i} id={sortedEntries.length - i} data={entry} view={view} /> : entry.sys.contentType.sys.id === "record" ? <RenderRecord key={i} id={sortedEntries.length - i} data={entry} view={view} /> : <RenderUnknown key={i} id={sortedEntries.length - i} data={entry} view={view} />
        )}
      </ul>
    </>
  )
}

export default Timeline


const RenderTransmission = ({ data, view, id }) => {
  const [ticket, setTicket] = useState(false)
  const { title, startTime } = data.fields
  let titleArray = [...title];
  let charToNo = [...title].slice(0, 3).map(letter => letter.charCodeAt(0)).join("").substring(0, 4)

  return (
    <>
      <li className={"timeline-entry timeline-entry-transmission"} style={{ display: view === "all" || view === "transmission" ? "flex" : "none" }} onMouseOver={() => setTicket(true)} onMouseOut={() => setTicket(false)}>
      <div className="timeline-entry-column timeline-entry-icon">
        <img className="icon" src={TransmissionIcon} alt="Transmission" />
      </div>
      <div className="timeline-entry-column timeline-entry-id">
        EV{padDigits(id, 4)}–{charToNo}
      </div>
      <div className="timeline-entry-column timeline-entry-date">
        ({startTime ? new Date(startTime).toLocaleDateString('en-US', dateFormat) : "TBA"})
      </div>
      <div className="timeline-entry-column timeline-entry-title">
        {titleArray.map((letter, i) => <span key={i} className="nth">{letter}</span>)}
      </div>
      <div className={"timeline-expand " + (ticket ? "display-block" : "display-none")}>
        <Ticket data={data} shadow="yellow" expanded="true" />
      </div>
    </li>
    </>
  )
}

const RenderRecord = ({ data, view, id }) => {
  const [ticket, setTicket] = useState(false)
  const { title, date } = data.fields

  let titleArray = [...title];
  let charToNo = [...title].slice(0, 3).map(letter => letter.charCodeAt(0)).join("").substring(0, 4)

  return (
    <li className={"timeline-entry timeline-entry-record"} style={{ display: view === "all" || view === "record" ? "flex" : "none" }} onMouseOver={() => setTicket(true)} onMouseOut={() => setTicket(false)}>
      <div className="timeline-entry-column timeline-entry-icon">
        <img className="icon" src={ReleaseIcon} alt="Release" />
      </div>
      <div className="timeline-entry-column timeline-entry-id">
        RE{padDigits(id, 4)}–{charToNo}
      </div>
      <div className="timeline-entry-column timeline-entry-date">
        ({date ? new Date(date).toLocaleDateString('en-US', dateFormat) : "TBA"})
      </div>
      <div className="timeline-entry-column timeline-entry-title">
        {titleArray.map((letter, i) => <span key={i} className="nth">{letter}</span>)}
      </div>


      <div className={"timeline-expand " + (ticket ? "display-block" : "display-none")}>
        <Record data={data} />
      </div>
    </li>
  )
}

const RenderUnknown = ({ data, view, id }) => {
  return (
    <>
      <li className={"timeline-entry timeline-entry-transmission"} style={{ display: view === "all" ? "flex" : "none" }}>
        <div className="timeline-entry-column timeline-entry-icon">
          <img className="icon" src={UnknownIcon} alt="Release" />
        </div>
        <div className="timeline-entry-column timeline-entry-id">
          UU{padDigits(id, 4)}–
        </div>
      </li>
    </>
  )
}

function padDigits(number, digits) {
  return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
}
