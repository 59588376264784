import { useContext } from 'react';
import { MusicPlayerContext } from "./MusicPlayerContext";

const useMusicPlayer = () => {
  const [state, setState] = useContext(MusicPlayerContext);

  // Play a specific track
  function playTrack(index) {
    if (index === state.currentTrackIndex) {
      togglePlay();
    } else {
      state.trackPlayer.pause();
      state.trackPlayer = new Audio(state.tracks[index].file);
      state.trackPlayer.play();
      setState(state => ({ ...state, currentTrackIndex: index, isPlayingTracks: true }));
    }
  }

  // Toggle play or pause

  function togglePlay() {
    if (state.currentTrackIndex === null) {
      return playTrack(0)
    }

    if (state.isPlayingTracks) {
      state.trackPlayer.pause();
    } else {
      state.trackPlayer.play();
    }

    setState(state => ({ ...state, isPlayingTracks: !state.isPlayingTracks }));
  }

  function stopTrack() {
    state.trackPlayer.pause();
    setState(state => ({ ...state, currentTrackIndex: null, isPlayingTracks: !state.isPlayingTracks }));
  }

  // Play the previous track in the tracks array
  function playPreviousTrack() {
    const newIndex = ((state.currentTrackIndex + -1) % state.tracks.length + state.tracks.length) % state.tracks.length;
    playTrack(newIndex);
  }

  // Play the next track in the tracks array
  function playNextTrack() {
    const newIndex = (state.currentTrackIndex + 1) % state.tracks.length;
    playTrack(newIndex);
  }

  state.trackPlayer.onended = () => playNextTrack();

  return {
    trackPlayer: state.trackPlayer,
    playTrack,
    togglePlay,
    currentTrackName: state.currentTrackIndex !== null && state.tracks[state.currentTrackIndex].name,
    trackList: state.tracks,
    isPlayingTracks: state.isPlayingTracks,
    playPreviousTrack,
    playNextTrack,
    stopTrack,
    memos: state.memos,
  }
};

export default useMusicPlayer;
