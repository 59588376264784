import React, { useState } from 'react';

const MusicPlayerContext = React.createContext([{}, () => {}]);

const MusicPlayerProvider = (props) => {

  const palette = ["var(--pink)", "var(--yellow)", "var(--purple)", "var(--blue)"]
  const newArray = !props.loadingData ? props.data.items[0].fields.radio.map((track, i) => ({name: track.fields.artists ? track.fields.artists[0].fields.name + " – " + track.fields.title : track.fields.title, file: track.fields.audio.fields.file.url})) : []
  const memoArray = !props.loadingData ? props.data.items[0].fields.memo.map((memo, i) => ({content: memo, color: palette[Math.floor(Math.random() * palette.length)]})) : []

  const [state, setState] = useState({
    trackPlayer: new Audio(),
    tracks: [],
    currentTrackIndex: null,
    isPlayingTracks: false,
    dataLoaded: props.loadingItemData ? true : false,
    memos: []
  });

  if (newArray.length !== state.tracks.length) {
    setState((prevState) => {
            prevState.tracks = newArray;
            return({
              ...prevState
            })
          }
        )
  }

  if (memoArray.length !== state.memos.length) {
    setState((prevState) => {
            prevState.memos = memoArray;
            return({
              ...prevState
            })
          }
        )
  }


  return (
    <MusicPlayerContext.Provider value={[state, setState]}>
      {props.children}
    </MusicPlayerContext.Provider>
  );
}

export { MusicPlayerContext, MusicPlayerProvider };
