import React, { useState } from 'react'
import './App.css';
import smoothscroll from 'smoothscroll-polyfill'
import { Components } from './data/data.js'
import Layout from './components/Layout'
import Client from './utilities/Client'
import Node from './components/Node'

smoothscroll.polyfill()

const App = () => {
  const [data, loadingData] = Client({ content_type: 'skeleton', order: '-sys.updatedAt', include: 5 })
  const [currentPlanet, setCurrentPlanet] = useState(0)

  if (loadingData) {return null}

  return (
    <>
    <Layout setCurrentPlanet={setCurrentPlanet} planetData={[data, loadingData]}>
      {Components.map((item, i) => (
        <Node
          key={item.id}
          item={item}
          sendData={[data, loadingData]}
          currentPlanet={currentPlanet}
        />
      ))}
    </Layout>
    <div className="silverring-holder">
    <div className="silverringen-object shadow" />
    </div>

    </>
  )
}

export default App
