import React, { useRef, useEffect } from 'react'
import Featured from '../components/Featured'
import Profile from '../components/Profile'
import Crew from '../components/Crew'
import Timeline from "../components/Timeline";

const Node = ({ item, sendData, currentPlanet }) => {
  const { id, slug, coordinate, component, scrollIntoView } = item
  const [data, loadingData] = sendData
  const ref = useRef()

  useEffect(() => {
    if (currentPlanet === id && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: scrollIntoView.block,
        inline: scrollIntoView.inline
      })
    }
  }, [currentPlanet, scrollIntoView.block, scrollIntoView.inline, id])

  const findComponent = [
  {
    id: 0,
    component: <Featured loadingData={loadingData} data={data} />,
  },
  {
    id: 1,
    component: <Crew loadingData={loadingData} data={data} />,
  },
  {
    id: 2,
    component: <Timeline loadingData={loadingData} data={data} />,
  },
  {
    id: 3,
    component: <Profile user={"Papa Musiq"} loadingData={loadingData} data={data} />,
  },
  {
    id: 4,
    component: <Profile user={"Isidor Estrada"} loadingData={loadingData} data={data} />,
  },
  ]

  return (
    <section ref={ref} className={"container " + slug} style={{ left: coordinate.x + "em", top: coordinate.y + "em" }}>
      {component ? findComponent[id].component : "No component"}
    </section>
  )
}

export default Node
