import React from 'react'

const Record = ({ data, shadow }) => {

  const { cover, title, tracks, linkForListening, linkForBuying } = data.fields

  return (
    <section className={"record"}>
      <div className={"record-inner " + (shadow ? shadow + "-shadow" : "shadow")}>
        <Cover className="record-cover" data={{cover, title}} />
        <Tracklist className="record-tracklist" data={{ title, tracks, linkForListening, linkForBuying }} />
      </div>
    </section>
  )
}

export default Record

const Cover = ({ data, className }) => {
  const { cover, title } = data

  return (
    <div className={className}>
      {cover
      ? <img src={cover.fields.file.url + "?w=320"} alt={"Cover for " + title} />
      : <span>No cover available atm</span>
      }
    </div>
  )
}

const Tracklist = ({ data, className }) => {
  const { title, tracks, linkForListening, linkForBuying } = data

  return (
    <div className={className}>
      <div className="record-headline">
        &nbsp;{title}&emsp;
        {tracks.length > 1 ? <span className="italic">(tracks)&ensp;</span> : null}
      </div>
      <Tracks data={tracks}/>
      <Links data={{linkForBuying, linkForListening}} />
    </div>
  )
}

const Tracks = ({ data }) => {
  return (
    <div className="record-tracks">
    {data.map((track, i) =>
      <div key={track.sys.id} className="record-track">
        <div className="record-track-title">
          A{(i + 1)}. {track.fields.title}
          {track.fields.artists ? <span className="record-track-artists italic base-color"> by{createList(track.fields.artists)}</span> : null}
        </div>
        <div>
          {track.fields.duration ? formatDuration(track.fields.duration) : "??:??"}
        </div>
      </div>
    )}
    </div>
  )
}

const Links = ({ data }) => {
  const { linkForBuying, linkForListening } = data

  return (
    <div className="record-ext-links">
      {linkForListening
        ? <a href={linkForListening} target="_blank" rel="noopener noreferrer">Listen</a>
        : null}
      {linkForListening && linkForBuying
        ? <span> or </span>
        : null}
      {linkForBuying
        ? <a href={linkForBuying} target="_blank" rel="noopener noreferrer">Get</a>
        : null}
    </div>
  )
}




function createList(x) {
  if (x.length === 1) {
    return " " + x[0].fields.name
  }

  const sum = []
  x.map((y, i) =>
    (i + 1) < x.length ?
      sum.push(" " + y.fields.name + ",")
    :
      sum.push(" " + y.fields.name)
  )

  return sum

}

function formatDuration (x) {
  if (x.length < 5) {
    return "0" + x
  } else if (x.length > 5) {
    return x.substring(0,4)
  } else {
    return x
  }
}
