import React from 'react'
import Marquee from 'react-double-marquee'
import SmallLogo from '../assets/silverring-logo-2.svg'

export default function Transmitter({ data }) {
  const marqueeItems = []
  const palette = ["var(--pink)", "var(--yellow)", "var(--purple)", "var(--blue)"]

  for(let i = 0; i < data.items[0].fields.memo.length; i++) {
    marqueeItems.push(
      <>
        <span style={{ color: palette[Math.floor(Math.random() * palette.length)] }}>
          {data.items[0].fields.memo[i]}
        </span>
        <img src={SmallLogo} alt="Silverring Stellar Sound" />
      </>
    )
  }

  return (
    <div className="marquee" style={{ whiteSpace: 'nowrap', display: "flex" }}>
      <Marquee children={marqueeItems} childMargin={0} direction={"left"} speed={0.1} />
    </div>
  )
}
