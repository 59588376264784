import React, { memo } from 'react'
import Starz from '../assets/starz.svg'

const Sky = () => {
  let starz = []
  for(let i = 0; i < 150; i++) {
    starz.push(
      <div key={i} className="star" style={{left: getRandomInt(100) + "%", top: getRandomInt(100) + "%" }}>
        <img src={Starz} alt="" />
      </div>
    )
  }

  return (
    <div className="skiez shadow">
      {starz}
    </div>
  )
}

export default memo(Sky)

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
