import React from 'react'
import Logo from '../assets/silverring-full-logo.svg'
import Ticket from './Ticket'
import Record from './Record'
import Draggable from 'react-draggable';
import { isMobile } from "react-device-detect";

const Featured = ({ loadingData, data }) => {
  if (loadingData === true) {return <p>Loading</p>}
  if (!data.total) {return <p>Loading</p>}

  return (
    <>
      <div className="featured-misc">
        <img src={Logo} className="featured-logo shadow-filter" alt="" />
        <p className="featured-seller">
          Silverring Stellar Sound is a collaborative spaceship and record label with a mission to create and curate music and human expression in all forms.
        </p>
      </div>
      <div className="featured-flex">
        {data.items[0].fields.featured.map((entry, i) =>
          <Draggable key={i} defaultPosition={isMobile ? { x: 0, y: 0 } : { x: getX(i, i.length), y: 100 + (getRandomInt(500) - 250) }} disabled={isMobile}>
          <div>
          <article key={i} className={"featured-entry shadow featured-" + entry.sys.contentType.sys.id + (isMobile ? " float-animation-disabled" : " float-animation")}>
            {entry.sys.contentType.sys.id === "transmission" ? <Ticket data={entry} shadow="yellow" expanded={false} /> : null}
            {entry.sys.contentType.sys.id === "record" ? <Record data={entry} shadow="yellow" /> : null}
          </article>
          </div>
          </Draggable>
        )}
        </div>
    </>
  )
}

export default Featured

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function getX(i, length) {
  return ((i - 1) * 100) + (Math.floor(Math.random() * Math.floor(250)) - 125)
}
