import { useState, useEffect } from 'react'
import { createClient } from 'contentful'

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN
});

const ClientHook = (settings) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    client.getEntries(settings).then(function(entries) {
      setData(entries)
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [])
  return [data, loading]
};

export default ClientHook
