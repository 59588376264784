import React, { useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Logo from '../assets/silverring-logo-2.svg'
import RenderDate from './RenderDate'

const Ticket = ({ data, shadow, expanded }) => {
  const [expandedState, setExpandedState] = useState(expanded)

  const { title, location, city, startTime, endTime, lineUp, description, link } = data.fields

  let titleArray = [...title];

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const widths = ['.2em', '.15em', '.10em', '.05em', '.2em', '.10em', '.15em', '.15em']
  const shapes = []
  for(let i = 0; i < 40; i++) {
    shapes.push(<div key={i} style={{ borderWidth: widths[getRandomInt(8)] }}/>);
  }

  return (
    <div className={"ticket " + (shadow ? shadow + "-shadow" : "no-shadow")} onClick={() => expandedState ? null : setExpandedState(true)}>
      <div className="ticket-upper-cut">
        {shapes}
      </div>
      <div className="ticket-padding">
      <div className="ticket-border">
      <div className="ticket-title">{titleArray.map((letter, i) => <span key={i}>{letter}</span>)}</div>
      <p className="location">
        {location ? "at " + location : "Location TBA"}
        {city && location ? ", " + city : null}
      </p>
      <RenderDate startTime={startTime} endTime={endTime} />
      <hr />
      <LineUp lineUp={lineUp} expanded={expandedState} />
      <div className={description && expandedState ? "display-block" : "display-none"}>
        {description ? <><hr />{documentToReactComponents(description)}</> : null}

      </div>
      {!expandedState
      ? <p>****** CLICK ******</p>
      : null }
      {link && expandedState
        ? <>
            <hr />
            <p className="uppercase">
            External WWW to Event<br />

            <a href={httpCheck(link)} target="_blank" rel="noopener noreferrer">
              [{extractDomain(link)}]
            </a>

            </p>

          </>
        : null
      }
      </div>
      <img src={Logo} className="logotype" style={{ margin: "1em 1em 0 1em", width: "calc(100% - 2em)", height: "5em", filter: "invert(1)"}} alt="" />

      </div>
      <div className="ticket-bottom-cut">
        {shapes}
      </div>
    </div>
  )
}

export default Ticket

const LineUp = ({ lineUp, expanded }) => {
  return (
    <div className={(expanded ? "display-block" : "display-none")}>
      <p className={"lineup uppercase"}>Line-Up</p>
      {documentToReactComponents(lineUp)}
    </div>
  )
}

function extractDomain(url) {
  var domain;
  if (url.indexOf("://") > -1) {
    domain = url.split('/')[2];
  }
  else {
    domain = url.split('/')[0];
  }
  if (domain.indexOf("www.") > -1) {
    domain = domain.split('www.')[1];
  }
  domain = domain.split(':')[0];
  domain = domain.split('?')[0];

  return domain;
}

function httpCheck(url) {
  if (url.indexOf("://") === -1) {
    return "https://" + url
  } else {
    return url
  }
}
